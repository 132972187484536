import Image from "next/image"
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import Link from 'next/link';
import useDeviceType from "hooks/useDeviceType";
import { useRouter } from "next/router";
import { useMemo } from "react";

interface PromoBannerProps {
  avaliableRegions: string[];
  backgroundImage: string
  mobileBackgroundImage: string
  backgroundColor: string
  title: string
  titleColor: string
  subtitle: string
  subtitleColor: string
  buttonText: string
  buttonColor: string
  buttonTextColor: string
  buttonUrl: string
  altText: string
  titleWidths?: string;
  subtitleWidths?: string;
  buttonCustomClasses?: string;
}

const PromoBanner: React.FC<PromoBannerProps> = (props) => {
  const {
    backgroundImage,
    mobileBackgroundImage,
    backgroundColor,
    title,
    titleColor,
    subtitle,
    subtitleColor,
    buttonText,
    buttonColor,
    buttonTextColor,
    buttonUrl,
    altText,
    titleWidths,
    subtitleWidths,
    buttonCustomClasses
  } = props;

  const deviceType = useDeviceType();

  const defaultWidths = {
    title: "max-w-[60%] smx:max-w-[70%] xxsmx:max-w-[90%] xsmx:max-w-[90%]",
    subtitle: "max-w-[60%] smx:max-w-[80%] xxsmx:max-w-[90%] xsmx:max-w-[90%]",
  };

  const defaultButtonClasses = 'bottom-[35px]';
  const titleWidthClasses = titleWidths || defaultWidths.title;
  const subtitleWidthClasses = subtitleWidths || defaultWidths.subtitle;
  const buttonClasses = buttonCustomClasses || defaultButtonClasses;

  return (
    <Link href={buttonUrl} className="block w-full">
      <div
        className="w-full h-[400px] overflow-hidden rounded-lg bg-cover bg-center bg-no-repeat relative cursor-pointer"
        style={{
          backgroundImage: `url(${deviceType.isMobile ? mobileBackgroundImage : backgroundImage})`,
          backgroundColor: backgroundColor,
          backgroundSize: 'cover',
          backgroundPosition: '100% 100%',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="absolute inset-0 flex flex-col justify-between p-8">
          <div className="flex flex-col">
            <h2
              className={`text-3xl md:text-2xl lg:text-3xl xl:text-4xl font-bold mb-4 ${titleWidthClasses}`}
              style={{ color: titleColor }}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className={`text-lg md:text-sm lg:text-base xl:text-lg mb-6 ${subtitleWidthClasses}`}
              style={{ color: subtitleColor }}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          </div>
          <div className={`absolute left-8 ${buttonClasses}`}>
            <button
              className="px-6 py-3 md:px-4 md:py-2 lg:px-6 lg:py-3 rounded-md text-lg md:text-base lg:text-lg font-semibold w-max"
              style={{ backgroundColor: buttonColor, color: buttonTextColor }}
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    </Link>
  )
}

export interface PromoBannersObject {
  firstOrderBanner: PromoBannerProps;
  nonFirstOrderBanner: PromoBannerProps;
  promotionBanners: PromoBannerProps[];
}

interface HomepageCampaignBannersProps {
  promoBanners: PromoBannersObject;
}

export default function HomepageCampaignBanners({ promoBanners }: HomepageCampaignBannersProps) {
  const user = useSelector((state: RootState) => state.authEvent.user);
  const router = useRouter();

  const hasOrdered = ('order_count' in user) && user.order_count > 0;

  const selectedPromotionBanner: any = useMemo(() => {
    return promoBanners.promotionBanners.find(banner => banner.avaliableRegions.includes(router.locale || 'au'));
  }, [promoBanners, router.locale]);

  return (
    <section className="bg-white">
      <div className="mx-auto 2xl:mt-6 2xl:mb-2 max-w-[1224px] px-4 mt-3 lg:px-4">
        <div className="flex flex-col lg:flex-row -mx-2">
          <div className="w-full lg:w-1/2 px-2 mb-4 lg:mb-0">
            {!hasOrdered ? (
              <PromoBanner {...promoBanners.firstOrderBanner} />
            ) : (
              <PromoBanner {...promoBanners.nonFirstOrderBanner} />
            )}
          </div>
          <div className="w-full lg:w-1/2 px-2">
            <PromoBanner {...selectedPromotionBanner} />
          </div>
        </div>
      </div>
    </section>
  )
}